.App {
  text-align: center;

  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.App-header {
  min-height: 10vh;
}

.footer-link {
  color: #1ca5cb;
  padding-left: 10px;
  padding-right: 10px;
}

.image {
  max-width: 80%;
}
